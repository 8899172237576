import axios from "axios";
import Cookies from "js-cookie";

const apiUrl = () => {
  return fetch("/config.json")
    .then((response) => response.json())
    .then((config) => config.apiUrl);
};

const configAuthLogin = () => {
  return {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
};

const configAuth = () => {
  return {
    withCredentials: true,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      Authorization: `Bearer ${Cookies.get("_webapp_client_jwt")}`,
    },
  };
};

const configAuthOffice = (token: string) => {
  return {
    withCredentials: true,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      Authorization: `Bearer ${token}`,
    },
  };
};

const configAuthOfficeFiles = (token: string) => {
  return {
    withCredentials: true,
    credentials: "include",
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      Authorization: `Bearer ${token}`,
    },
  };
};

const configAuthFiles = () => {
  return {
    withCredentials: true,
    credentials: "include",
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      Authorization: `Bearer ${Cookies.get("_webapp_client_jwt")}`,
    },
  };
};

const checkToken = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(
      url + "api/authentication/checkToken",
      values,
      configAuthLogin()
    );
  });
};

const login = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/authentication/login", values);
  });
};

const logout = (token: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(
      url + "api/authentication/logout",
      configAuthOffice(token)
    );
  });
};

const isTwoFactor = (token: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/user/istwofactor", configAuthOffice(token));
  });
};

const validatetwofactor = (values: any, token: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(
      url + "api/user/validatetwofactor",
      values,
      configAuthOffice(token)
    );
  });
};

const checkDocument = (values: any, token: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(
      url + "api/task/check/document",
      values,
      configAuthOfficeFiles(token)
    );
  });
};

const getTasks = (token: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/tasks/active", configAuthOffice(token));
  });
};

const getTaskBase64 = (id: string, token: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/task/base64/" + id, configAuthOffice(token));
  });
};

const getTaskHistory = (id: string, token: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/task/history/" + id, configAuthOffice(token));
  });
};

const addTaskHistory = (values: any, token: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(
      url + "api/task/history",
      values,
      configAuthOffice(token)
    );
  });
};

const getTask = (id: string, token: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/task/" + id, configAuthOffice(token));
  });
};

const cancelTask = (id: string, token: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/task/cancel/" + id, configAuthOffice(token));
  });
};

const userInfo = () => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/authentication/userinfo", configAuth());
  });
};

const getUsers = () => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/users", configAuth());
  });
};

const getDownloadTask = (id: string) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/task/download/" + id, configAuth());
  });
};

const editTask = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/task/edit", values, configAuth());
  });
};

const workflowSubmit = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/task/workflow", values, configAuth());
  });
};

const taskUpload = (values: any) => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.post(url + "api/task/upload", values, configAuthFiles());
  });
};

const getUserTerms = () => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/user/terms", configAuth());
  });
};

const getUserPrivacy = () => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/user/privacy", configAuth());
  });
};

const setUserTerms = () => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/user/terms/set", configAuth());
  });
};

const setUserPrivacy = () => {
  return apiUrl().then((url) => {
    if (!url) {
      throw new Error("API URL not found");
    }
    return axios.get(url + "api/user/privacy/set", configAuth());
  });
};

const ApiService = {
  apiUrl,
  login,
  logout,
  userInfo,
  isTwoFactor,
  validatetwofactor,
  getUsers,
  getTasks,
  getTask,
  editTask,
  getDownloadTask,
  workflowSubmit,
  getTaskHistory,
  taskUpload,
  addTaskHistory,
  cancelTask,
  getUserTerms,
  getUserPrivacy,
  setUserTerms,
  setUserPrivacy,
  checkToken,
  checkDocument,
  getTaskBase64,
};

export default ApiService;
