/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import ApiService from "../../services/ApiService";
import moment from "moment";
import { PersonFill } from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import CloseButton from "react-bootstrap/CloseButton";
import { SlidesDisplaySimple } from "../common/slides-display-simple";
import { useGlobalContext } from "../../GlobalContext";

function TaskHistory(props: any) {
  const [items, setItems] = useState([]);
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState([] as Error[]);
  const [comment, setComment] = useState("");
  const context = useGlobalContext();

  useEffect(() => {
    getItems();
  }, [context.currentTask]);

  const getItems = () => {
    ApiService.getTaskHistory(props.id, context.currentToken).then(
      (response: any) => {
        console.log("Task history -->");
        console.log(response.data);
        setItems(response.data);
      }
    );
  };

  const handleCommentChange = (event: any) => {
    setComment(event.target.value);
  };

  const addTaskHistory = () => {
    try {
      const formData = new FormData();
      formData.append("taskId", props.id);
      formData.append("description", comment);
      ApiService.addTaskHistory(formData, context.currentToken).then(
        (response: any) => {
          if (response.data.length > 0) {
            setErrors(response.data);
            setIsValid(false);
          } else {
            setComment("");
            setIsValid(true);
            getItems();
          }
        }
      );
    } catch (e: any) {
      setIsValid(false);
      console.log("Error -> " + e.toJSON().message);
    }
  };

  return (
    <>
      {!isValid &&
        errors.map((error: Error, index) => (
          <div
            className="alert alert-warning alert-dismissible fade show"
            role="alert"
            key={index}
          >
            {error.message}
            <CloseButton onClick={() => setIsValid(true)} />
          </div>
        ))}
      <h5>Add Comment</h5>
      <div className="mb-3">
        <textarea
          id="activity"
          className="form-control"
          value={comment}
          onChange={handleCommentChange}
        />
      </div>
      <div className="mb-3">
        <div className="row mb-3">
          <div className="col-md-3 col-sm-6 text-left">&nbsp;</div>
          <div className="col-md-9 col-sm-6 text-right">
            <Button variant="primary" onClick={addTaskHistory}>
              Add Comment
            </Button>
          </div>
        </div>
      </div>
      <hr />
      {items.map((item: any, index: number) => (
        <>
          {index > 0 && <hr />}
          <span
            key={item.id}
            className="list-group-item list-group-item-action py-3 lh-tight"
          >
            <div className="d-flex w-100 align-items-center justify-content-between">
              <strong className="mb-1">{item.action}</strong>
              <small
                className="badge rounded-pill bg-secondary"
                title={moment(item.created).format("YYYY/MM/DD HH:mm")}
              >
                {item.createdString}
              </small>
            </div>
            <div className="col-10 mb-1 small">
              {item.description?.trim() && <span>{item.description}</span>}
              {item.fileAttached && (
                <>
                  {item.filesBase64 != null && (
                    <SlidesDisplaySimple
                      slides={item.filesBase64}
                      initialSize="col-md-4"
                    />
                  )}
                  {item.filesPath != null && (
                    <SlidesDisplaySimple
                      slides={item.filesPath}
                      initialSize="col-md-4"
                    />
                  )}
                </>
              )}
            </div>
            <PersonFill /> <em>{item.createdBy.fullName}</em>
          </span>
        </>
      ))}
    </>
  );
}

export { TaskHistory };
