import React, { useEffect, useState } from "react";
import {
  HashRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
} from "react-router-dom";
import { Dashboard } from "./components/dashboard/dashboard";
import { Login } from "./components/user/login";
import { useGlobalContext } from "./GlobalContext";
import ApiService from "./services/ApiService";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "./assets/images/logo.png";
import "./assets/css/theme.css";
import "./css/app.css";
import { useHistory } from "react-router-dom";
import { CheckFile } from "./context/UserContext";
import Spinner from "react-bootstrap/Spinner";
import { set } from "./assets/js/config";
import { Welcome } from "./components/user/welcome";

function App() {
  const [isOffice, setIsOffice] = useState(false);
  const context = useGlobalContext();
  const [currentpath, setCurrentPath] = useState("");
  let history = useHistory();
  const [formData, setFormData] = useState<CheckFile>({
    file: null,
    action: "",
    note: "",
  });
  const [fileAction, setFileAction] = useState("filecheck");
  const [fileNote, setFileNote] = useState("filecheck");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    Office.onReady(() => {
      setIsOffice(true);
      console.log("Office is ready");
    });

    const isFirstVisit =
      localStorage.getItem("slidecollab_first_visit") === null;
    if (isFirstVisit) {
      localStorage.setItem("slidecollab_first_visit", "no");
      history.push("/welcome"); // Replace '/welcome' with your welcome page route
    }

    if (localStorage.getItem("slidecollab-auth-token")) {
      const token = localStorage.getItem("slidecollab-auth-token");
      const formData = new FormData();
      formData.append("token", token?.toString() as string);
      formData.append("timezoneoffset", "0");
      try {
        ApiService.checkToken(formData).then((response) => {
          if (response.data.token !== "") {
            context.setCurrentUser(response.data.user.id);
            context.setCurrentUserName(response.data.user.fullName);
            context.setCurrentToken(response.data.token);
            history.push("/");
          } else {
            localStorage.removeItem("slidecollab-auth-token");
            context.setCurrentUser("");
            context.setCurrentToken("");
          }
        });
      } catch (err) {
        localStorage.removeItem("slidecollab-auth-token");
        context.setCurrentUser("");
        context.setCurrentToken("");
      }
    } else {
      //uploadFile("login");
    }
  }, []);

  useEffect(() => {
    if (context.currentUser !== "") {
      uploadFile("fileCheck", "fileCheck");
    }
  }, [context.currentToken]);

  const uploadFile = async (action: string, note: string) => {
    Office.context.document.getFileAsync(
      Office.FileType.Compressed,
      { sliceSize: 4194304 },
      async (result: any) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          const file = result.value;
          const sliceCount = file.sliceCount;
          const slicesReceived = new Array(sliceCount);
          let slicesReceivedCount = 0;

          const getSliceContent = (sliceIndex: number) => {
            file.getSliceAsync(sliceIndex, (sliceResult: any) => {
              if (sliceResult.status === Office.AsyncResultStatus.Succeeded) {
                // Convert the slice data to Uint8Array and store it
                const sliceUint8Array = new Uint8Array(sliceResult.value.data);
                slicesReceived[sliceIndex] = sliceUint8Array;
                slicesReceivedCount++;

                if (slicesReceivedCount === sliceCount) {
                  // All slices have been received
                  file.closeAsync();
                  const blob = new Blob(slicesReceived, {
                    type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                  });
                  uploadToServer(blob, action, note); // Upload the Blob
                }
              } else {
                console.error(
                  "Failed to get slice: ",
                  sliceResult.error.message
                );
              }
            });
          };

          for (let i = 0; i < sliceCount; i++) {
            getSliceContent(i);
          }
        } else {
          console.error("Failed to get file: ", result.error.message);
        }
      }
    );
  };

  const uploadToServer = async (blob: Blob, action: string, note: string) => {
    try {
      setIsLoading(true);
      let tmpFormData = formData;
      tmpFormData.file = blob;
      tmpFormData.action = action;
      tmpFormData.note = note;
      context.setCurrentTask("");
      try {
        ApiService.checkDocument(tmpFormData, context.currentToken).then(
          (response) => {
            console.log("File uploaded successfully:", response);
            if (response.data.userHasAccess) {
              context.setCurrentTask(response.data.task.id);
              context.setCurrentTaskFileLock(true);
            } else {
              context.setCurrentTask("");
              context.setCurrentTaskFileLock(false);
            }
            setIsLoading(false);
            // if (type === "login") {
            //   context.setCurrentUser(response.data.user.id);
            //   context.setCurrentToken(response.data.token);
            //   history.push("/");
            // }
          }
        );
      } catch (err) {
        console.error("Error uploading file:", err);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("Error uploading file:", error);
      setIsLoading(false);
    }
  };

  const logout = async () => {
    localStorage.removeItem("slidecollab-auth-token");
    await ApiService.logout(context.currentToken);
    context.setCurrentUser("");
    context.setCurrentToken("");
    context.setCurrentTask("");
    context.setCurrentTaskFileLock(false);
    context.setCurrentUserName("");
    history.push("/login");
  };

  function getLogoutAction() {
    if (context.currentUser != "") {
      return (
        <a href="#" onClick={logout}>
          Log Out
        </a>
      );
    } else {
      return <span></span>;
    }
  }

  function getMenuItems() {
    if (context.currentUser !== "") {
      return (
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link
              className={currentpath === "/" ? "nav-link fw-bold" : "nav-link"}
              aria-current="page"
              to={"/"}
              onClick={() => setCurrentPath("/")}
            >
              Dashboard
            </Link>
          </li>
        </ul>
      );
    } else {
      return (
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link className="nav-link" aria-current="page" to={"#"}>
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" aria-current="page" to={"/login"}>
              Sign In
            </Link>
          </li>
        </ul>
      );
    }
  }

  return (
    <Router>
      {isLoading && (
        <div className="spinner-background-95">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <Navbar className="navbar navbar-top navbar-expand-lg fixed-top">
        <Container>
          <Navbar.Brand href="#home">
            <img src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">{getMenuItems()}</Nav>
            <Nav>
              {context.currentUserName} &nbsp;&nbsp;{getLogoutAction()}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/welcome" component={Welcome} />
        {context.currentUser !== "" && (
          <Route
            exact
            path="/"
            render={() => <Dashboard uploadFile={uploadFile} />}
          />
        )}
        {context.currentUser === "" && (
          <>
            <Route exact path="/" render={() => <Login />} />
          </>
        )}
      </Switch>
    </Router>
  );
}

export default App;
