import React, { useState } from "react";
import { useGlobalContext } from "../../GlobalContext";
import ApiService from "../../services/ApiService";
import {
  BoxArrowRight,
  ArrowLeft,
  ArrowUp,
  ArrowDown,
  PencilSquare,
  CheckSquare,
  ChatSquareText,
  ArrowClockwise,
} from "react-bootstrap-icons";
import Table from "react-bootstrap/Table";
import { SlidesDisplay } from "../common/slides-display";
import { AppLink } from "../common/applink";

function TasksList(props: any) {
  const context = useGlobalContext();
  const [showSlides, setShowSlides] = useState(false);
  const [taskName, setTaskName] = useState("");
  const [slidesOriginal, setSlidesOriginal] = useState([]);
  const [slidesUpdated, setSlidesUpdated] = useState([]);
  const [sortColumn, setSortColumn] = useState<string | null>("lastActivity");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  const openPowerPoint = async (id: string) => {
    try {
      ApiService.getTaskBase64(id, context.currentToken).then((response) => {
        if (response.data !== "") {
          PowerPoint.createPresentation(response.data);
          return response.data.text();
        } else {
          return "";
        }
      });
    } catch (error) {
      console.error("Error opening PowerPoint:", error);
    }
  };

  const toggleSort = (column: string) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const sortItems = (items: any[]) => {
    if (!sortColumn) return items;

    return [...items].sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) {
        return sortDirection === "asc" ? -1 : 1;
      }
      if (a[sortColumn] > b[sortColumn]) {
        return sortDirection === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  const renderSortArrow = (column: string) => {
    if (sortColumn !== column) {
      return null;
    }

    if (sortDirection === "desc") {
      return <ArrowDown />;
    } else {
      return <ArrowUp />;
    }
  };

  const getUserRoleIcon = (role: string) => {
    if (role === "Editor") {
      return <PencilSquare title="Editor" />;
    } else if (role === "Approver") {
      return <CheckSquare title="Approver" />;
    } else if (role === "Reviewer") {
      return <ChatSquareText title="Reviewer" />;
    } else {
      return null;
    }
  };

  return (
    <>
      {!showSlides && (
        <>
          <a
            href="#"
            onClick={() =>props.getItems()}
            title="Refresh"
            className="mouse-pointer float-end"
          >
            <ArrowClockwise/> Refresh
          </a>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th
                  className="mouse-pointer"
                  onClick={() => toggleSort("name")}
                >
                  Name&nbsp;{renderSortArrow("name")}
                </th>
                <th
                  className="mouse-pointer"
                  onClick={() => toggleSort("lastActivity")}
                >
                  Status/Activity/Slides&nbsp;{renderSortArrow("lastActivity")}
                </th>
                <th>Users</th>
              </tr>
            </thead>
            <tbody>
              {sortItems(props.items).map((item: any) => (
                <tr key={item.id}>
                  <td>
                    <span
                      title="Open task in PowerPoint"
                      onClick={() => openPowerPoint(item.id)}
                      className=" mouse-pointer"
                    >
                      {item.name} <BoxArrowRight size={20} />
                    </span>
                  </td>
                  <td>
                    {item.workflowStatusName === "In Progress" && (
                      <span className="badge text-bg-primary">
                        {item.workflowStatusName}
                      </span>
                    )}
                    {item.workflowStatusName === "Pending Approval" && (
                      <span className="badge text-bg-warning">
                        {item.workflowStatusName}
                      </span>
                    )}
                    {item.workflowStatusName === "Completed" && (
                      <span className="badge text-bg-light">
                        {item.workflowStatusName}
                      </span>
                    )}
                    &nbsp;
                    <span
                      className="badge rounded-pill bg-secondary"
                      title="Last activity"
                    >
                      {item.lastActivityString}
                    </span>
                    &nbsp;
                    <span
                      className="badge rounded-pill bg-dark mouse-pointer"
                      onClick={() => {
                        setSlidesOriginal(item.slidesOriginal);
                        setSlidesUpdated(item.slidesUpdated);
                        setTaskName(item.name);
                        setShowSlides(true);
                      }}
                    >
                      {item.slides.length}
                    </span>
                  </td>
                  <td>
                    {item.users.map((user: any) => (
                      <span
                        key={user.id}
                        className="taskUser badge bg-primary me-1"
                        title={user.fullName + " (" + user.role + ")"}
                      >
                        {user.initials} {getUserRoleIcon(user.role)}
                      </span>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
      {showSlides && (
        <div className="row">
          <div className="col-12 text-left">
            <span
              title="Go back"
              onClick={() => setShowSlides(false)}
              className="mouse-pointer"
            >
              <ArrowLeft size={20} />
            </span>
            <hr />
            <h5>{taskName}</h5>
          </div>
          <div className="col-12">
            <SlidesDisplay
              originalSlides={slidesOriginal}
              updatedSlides={slidesUpdated}
              initialSize="col-md-12"
            />
          </div>
        </div>
      )}
      <AppLink />
    </>
  );
}

export { TasksList };
