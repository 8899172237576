import React, { useContext, useState } from "react";
import { UserContext, User } from "../../context/UserContext";
import { useFormik } from "formik";
import ApiService from "../../services/ApiService";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { useGlobalContext } from "../../GlobalContext";
import { useHistory } from "react-router-dom";
import { AppLinkSimple } from "../common/applinksimple";

function Login(props: any) {
  const { setUser } = useContext(UserContext);
  const [isValid, setIsValid] = useState(true);
  const context = useGlobalContext();
  let history = useHistory();

  const onSubmit = async (values: any) => {
    try {
      const response = await ApiService.login(values);
      const user: User = {
        id: response.data.user.id,
        name: response.data.user.fullName,
        email: response.data.user.email,
        isadmin: response.data.user.isAdmin,
        istenantadmin: response.data.user.isTenantAdmin,
        istwoFactor: response.data.user.isTwoFactor,
      };
      context.setCurrentUser(response.data.user.id);
      context.setCurrentUserName(response.data.user.fullName);
      context.setCurrentToken(response.data.token);
      context.setUserCollabTrans(response.data.user.transCollaboration);
      context.setUserCollabsTrans(response.data.user.transCollaborations);
      context.setUserTaskTrans(response.data.user.transTask);
      context.setUserTasksTrans(response.data.user.transTasks);
      localStorage.setItem("slidecollab-auth-token", response.data.token);
      console.log(response.data);
      setUser(user);
      setIsValid(true);
      history.push("/");
    } catch (err) {
      setIsValid(false);
      console.log("Error: ", err);
    }
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      timezoneoffset: new Date().getTimezoneOffset(),
    },
    onSubmit,
  });

  return (
    <div className="container-fluid px-0">
      <div className="container">
        <div className="row flex-center py-5">
          <div className="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-3">
            <div className="row">
              <div className="col-md-12">&nbsp;</div>
              <div className="col-md-12">&nbsp;</div>
            </div>
            <h3>Sign In</h3>
            <span className="p-3"></span>
            {!isValid && (
              <Alert variant="danger">
                Sorry, we couldn't log you in. Either your username or password
                is incorrect, or your account may not yet be activated. Please
                try entering your details again. If you're attempting to log in
                for the first time, ensure that you have activated your account
                by clicking the activation link sent to your email.
              </Alert>
            )}
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  name="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  placeholder="Enter email"
                  type="email"
                />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  placeholder="Password"
                  type="password"
                />
              </Form.Group>
              <div className="text-center mb-2">&nbsp;</div>
              <Button type="submit" className="btn btn-primary w-100 mb-3">
                Sign In
              </Button>
              <AppLinkSimple />
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Login };
