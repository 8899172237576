import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { GlobalProvider } from "./GlobalContext";
import { HashRouter as Router } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

Office.initialize = () => {
  root.render(
    <React.StrictMode>
      <GlobalProvider>
        <Router>
          <App />
        </Router>
      </GlobalProvider>
    </React.StrictMode>
  );
};
