function Welcome(props: any) {
  return (
    <div className="container-fluid px-0">
      <div className="container">
        <div className="row flex-center py-5">
          <div className="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-3 text-center">
            <div className="row">
              <div className="col-md-12">&nbsp;</div>
              <div className="col-md-12">&nbsp;</div>
            </div>
            <h3>Welcome</h3>
            <div className="col-md-12">&nbsp;</div>
            The SlideCollab web application enables users to upload
            presentations, assign tasks (slides) to other users, who can then access and
            edit these tasks through this PowerPoint web add-in, with the
            ability to save work, add comments, and upon completion, merge their
            updates back into the original presentation.
            <div className="col-md-12">&nbsp;</div>
            Please check out our website for more information at{" "}
            <a href="https://www.slidecollab.com" target="_new">
              https://www.slidecollab.com
            </a>
          </div>
          <span className="p-3"></span>
          <a href="/login" className=" text-center">
            Go to Login
          </a>
        </div>
      </div>
    </div>
  );
}

export { Welcome };
