import React, { useState } from "react";
import { ClipboardCheckFill } from "react-bootstrap-icons";

function AppLinkSimple() {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText("https://app.slidecollab.com")
      .then(() => {
        setCopied(true);
      })
      .catch((err) => {
        console.error("Error in copying text: ", err);
      });
  };

  return (
    <>
      <div className="text-center mt-2">
        <div className="alert alert-info" role="alert">
          If you need to create a new account or reset your password please go
          to the main web application at{" "}
          <a href="https://app.slidecollab.com" target="_new">
            https://app.slidecollab.com
          </a>
          <ClipboardCheckFill
            className="ms-2"
            onClick={copyToClipboard}
            title="Copy to clipboard"
          />
          {copied && (
            <div className="alert alert-light mt-3" role="alert">
              <strong>https://app.slidecollab.com</strong> copied to clipboard!
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export { AppLinkSimple };
