import React, { createContext, useState } from "react";

export interface UserList {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  active: boolean;
  isTwoFactor: boolean;
  role: string;
  usersRefresh(): any;
}

export interface User {
  id: string;
  name: string;
  email: string;
  isadmin: boolean;
  istenantadmin: boolean;
  istwoFactor: boolean;
}

export interface UserSignup {
  email: string;
  password: string;
  firstname: string;
  lastname: string;
  companyname: string;
}

export interface UserAdd {
  email: string;
  password: string;
  firstname: string;
  lastname: string;
}

export interface UserEdit {
  id: string;
  firstname: string;
  lastname: string;
  roleid: string;
}

export interface TenantSettingsEdit {
  id: string;
  collaboration: string;
  collaborations: string;
  task: string;
  tasks: string;
}

export interface InviteAdd {
  email: string;
}

interface UserContextState {
  user: User | null;
  setUser: (user: User | null) => void;
}

interface UserContextProps {
  children: React.ReactNode;
}

export const UserContext = createContext<UserContextState>({
  user: null,
  setUser: () => {},
});

export const UserProvider = ({ children }: UserContextProps) => {
  const [user, setUser] = useState<User | null>(null);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export interface Error {
  type: string;
  message: string;
  stackTrace: string;
}

export interface TaskAdd {
  collaboration: string;
  workflow: string;
  name: string;
  description: string;
  slides: string[];
  users: string[];
}

export interface TaskHistory {
  id: string;
  actionId: number;
  action: string;
  description: string;
  created: string;
  user: string;
  file: boolean;
}

export interface CheckFile {
  file: Blob | null;
  action: string;
  note: string;
}