/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";

function SlidesDisplay(props: any) {
  const [slidesize, setSlidesize] = useState("col-md-6");

  useEffect(() => {
    if (props.initialSize) {
      setSlidesize(props.initialSize);
    }
  }, []);

  return (
    <>
      <div className="inner-activity">
        <div className="row">
          <div className=" mb-3">
            <a
              href="#"
              className={slidesize === "col-md-2" ? " fw-bold" : ""}
              onClick={() => setSlidesize("col-md-2")}
            >
              Small
            </a>{" "}
            -{" "}
            <a
              href="#"
              className={slidesize === "col-md-4" ? " fw-bold" : ""}
              onClick={() => setSlidesize("col-md-4")}
            >
              Medium
            </a>{" "}
            -{" "}
            <a
              href="#"
              className={slidesize === "col-md-6" ? " fw-bold" : ""}
              onClick={() => setSlidesize("col-md-6")}
            >
              Large
            </a>{" "}
            -{" "}
            <a
              href="#"
              className={slidesize === "col-md-12" ? " fw-bold" : ""}
              onClick={() => setSlidesize("col-md-12")}
            >
              XLarge
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <h4>Original</h4>
            <div className="row">
              {props.originalSlides.map(
                (item: any) => (
                  <div key={item.id} className={slidesize}>
                    <img
                      src={item.filePath}
                      alt="Thumbnail"
                      className="slideImage mb-1"
                    />
                  </div>
                ),
                []
              )}
            </div>
          </div>
          <div className="col-md-6">
            <h4>Updated</h4>
            <div className="row">
              {props.updatedSlides.map((item: any) => (
                <div key={item.id} className={`${slidesize} slideContainer`} title="Assigned">
                  <img
                    src={item.filePath}
                    alt="Thumbnail"
                    className={`slideImage ${
                      item.isAssigned ? "mb-1" : "mb-1"
                    }`}
                    style={{ opacity: item.isAssigned ? 0.2 : 1 }}
                  />
                  {item.isAssigned && (
                    <div className="assignedText">
                      <em>{item.isAssignedTaskName}</em>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { SlidesDisplay };
