import React, { useState, useEffect } from "react";
import { useGlobalContext } from "../../GlobalContext";
import ApiService from "../../services/ApiService";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { TaskHistory } from "./tasks-history";
import { SlidesDisplay } from "../common/slides-display";
import { ArrowDownSquare, ArrowUpSquare, Check, X, Trash } from "react-bootstrap-icons";
import Spinner from "react-bootstrap/Spinner";

function TaskDetails(props: any) {
  const context = useGlobalContext();
  const [item, setItem] = useState();
  const [itemActions, setItemActions] = useState([]);
  const [itemCanCancel, setItemCanCancel] = useState(false);
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);
  const [showSlides, setShowSlides] = useState(false);

  const [itemName, setItemName] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [itemDateCreated, setItemDateCreated] = useState("");
  const [itemCollaboration, setItemCollaboration] = useState("");
  const [itemStatus, setItemStatus] = useState("");
  const [itemActivity, setItemActivity] = useState("");
  const [itemUsers, setItemUsers] = useState([]);
  const [itemSlidesOriginal, setItemSlidesOriginal] = useState([]);
  const [itemSlidesUpdated, setItemSlidesUpdated] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getItem();
  }, [context.currentTask]);

  const getItem = async () => {
    setIsLoading(true);
    try {
      const response = await ApiService.getTask(
        context.currentTask,
        context.currentToken
      );
      setItem(response.data);
      setItemActions(response.data.actions);
      setItemCanCancel(response.data.canCancel);
      setItemName(response.data.name);
      setItemDescription(response.data.description);
      setItemDateCreated(response.data.createdStringSimple);
      setItemCollaboration(response.data.collaboration.name);
      setItemStatus(response.data.workflowStatusName);
      setItemActivity(response.data.lastActivityString);
      setItemUsers(response.data.users);
      setItemSlidesOriginal(response.data.slidesOriginal);
      setItemSlidesUpdated(response.data.slidesUpdated);
      console.log(response.data);
      setIsLoading(false);
    } catch (e) {
      console.log("Error");
      setIsLoading(false);
    }
  };

  const handleButtonCancelClick = () => {
    setShowConfirmCancel(true);
  };

  const handleCancelCancel = () => {
    setShowConfirmCancel(false);
  };

  const cancelTask = async () => {
    try {
      const response = await ApiService.cancelTask(
        context.currentTask,
        context.currentToken
      );
      console.log(response.data);
      getItem();
    } catch (e: any) {
      console.log("Error -> " + e.toJSON().message);
    }
  };

  const saveFile = () => {
    props.uploadFile("save", "test");
    getItem();
  };

  const submitFile = (action: string) => {
    props.uploadFile(action, "test");
    getItem();
  };

  return (
    <>
      {isLoading && (
        <div className="spinner-background">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <Tabs
        defaultActiveKey="taskInfo"
        id="uncontrolled-tab-example"
        className="mb-3"
        fill
      >
        <Tab eventKey="taskInfo" title="Details">
          <div className="row">
            <div className="col-6 text-left">
              <p className="fw-light">Name</p>
            </div>
            <div className="col-6 text-right">
              <p className="fw-normal">{itemName}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-6 text-left">
              <p className="fw-light">Date Created</p>
            </div>
            <div className="col-6 text-right">
              <span className="badge rounded-pill bg-secondary">
                {itemDateCreated}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-6 text-left">
              <p className="fw-light">{context.userCollabTrans}</p>
            </div>
            <div className="col-6 text-right">
              <p className="fw-normal">{itemCollaboration}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-6 text-left">
              <p className="fw-light">Status</p>
            </div>
            <div className="col-6 text-right">
              <p className="fw-normal">
                {itemStatus === "In Progress" && (
                  <span className="badge text-bg-primary">{itemStatus}</span>
                )}
                {itemStatus === "Pending Approval" && (
                  <span className="badge text-bg-warning">{itemStatus}</span>
                )}
                {itemStatus === "Completed" && (
                  <span className="badge text-bg-light">{itemStatus}</span>
                )}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-6 text-left">
              <p className="fw-light">Activity</p>
            </div>
            <div className="col-6 text-right">
              <p className="fw-normal">
                <span className="badge rounded-pill bg-secondary mouse-pointer">
                  {itemActivity}
                </span>{" "}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-6 text-left">
              <p className="fw-light">Users</p>
            </div>
            <div className="col-6 text-right mb-2">
              {itemUsers.map((user: any) => (
                <span
                  key={user.id}
                  className="taskUser badge bg-primary me-1"
                  title={user.fullName}
                >
                  {user.initials} ({user.role})
                </span>
              ))}
            </div>
          </div>
          <div className="row">
            <div className="col-6 text-left">
              <p className="fw-light">Slides</p>
            </div>
            <div className="col-6 text-right">
              <p className="fw-normal">
                {showSlides && (
                  <span
                    className="mouse-pointer"
                    onClick={() => setShowSlides(false)}
                  >
                    <ArrowUpSquare size={20} title="Hide slides" />
                  </span>
                )}
                {!showSlides && (
                  <span
                    className="mouse-pointer"
                    onClick={() => setShowSlides(true)}
                  >
                    <ArrowDownSquare size={20} title="Show slides" />
                  </span>
                )}
              </p>
            </div>
          </div>
          {showSlides && (
            <div className="row">
              <div className="col-12 text-left">
                <SlidesDisplay
                  originalSlides={itemSlidesOriginal}
                  updatedSlides={itemSlidesUpdated}
                  initialSize="col-md-12"
                />
              </div>
            </div>
          )}
        </Tab>
        <Tab eventKey="taskActivity" title="Activity">
          <TaskHistory id={context.currentTask} />
        </Tab>
      </Tabs>
      <div className="row">
        <div className="col-md-12">&nbsp;</div>
        <div className="col-md-12">&nbsp;</div>
        <div className="col-md-12">&nbsp;</div>
      </div>
      {showConfirmCancel && (
        <div className="row fixed-bottom background-white">
          <div className="row mb-3 mt-3 mx-1">
            <div className="col-12 text-left">
              <div className="confirmation-dialog">
                <p>Are you sure you want to cancel?</p>
                <button className="btn btn-primary" onClick={cancelTask}>
                  Yes
                </button>
                &nbsp;
                <button
                  className="btn btn-secondary"
                  onClick={handleCancelCancel}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {!showConfirmCancel && (
        <div className="row fixed-bottom background-white">
          <div className="row mb-3 mt-3 mx-1">
            <div className="col-3 text-left">
              {itemStatus !== "Completed" && (
                <button className="btn btn-primary" onClick={() => saveFile()}>
                  Save
                </button>
              )}
            </div>
            <div className="col-9 text-right">
              {itemCanCancel && (
                <button
                  className="btn btn-primary"
                  onClick={() => handleButtonCancelClick()}
                >
                  <Trash size={20} title="Cancel" />
                </button>
              )}
              {item && (
                itemActions.map((action: any) => (
                  <>
                  &nbsp;
                    <button
                      key={action.id}
                      className="btn btn-primary"
                      onClick={() => submitFile(action.name)}
                    >
                      {action.name == "Approve" && <Check size={20} title={action.name} />}
                      {action.name == "Decline" && <X size={20} title={action.name} />}
                      {action.name != "Approve" && action.name != "Decline" && action.name}
                    </button>
                  </>
                )))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export { TaskDetails };
