import React, { useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import { useGlobalContext } from "../../GlobalContext";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { TasksList } from "../tasks/tasks-list";
import { TaskDetails } from "../tasks/task-details";
import { AppLink } from "../common/applink";

function Dashboard(props: any) {
  const context = useGlobalContext();
  const [items, setItems] = useState([]);
  const [itemsRefresh, setItemsRefresh] = useState(false);

  useEffect(() => {
    getItems();
  }, []);

  useEffect(() => {
    getItems();
  }, [itemsRefresh, setItemsRefresh]);

  const getItems = async () => {
    try {
      const response = await ApiService.getTasks(context.currentToken);
      setItems(response.data);
      console.log(response.data);
    } catch (e) {
      console.log("Error");
    }
  };

  return (
    <>
      <div className="row mx-1">
        <div className="col-md-12">&nbsp;</div>
        <div className="col-md-12">&nbsp;</div>
        <div className="col-md-12">&nbsp;</div>
      </div>
      <div className="row mx-1">
        <div className="col-md-12 mt-3">
          <Tabs id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="task" title={"Current " + context.userTaskTrans}>
              {context.currentTask !== "" ? (
                <TaskDetails uploadFile={props.uploadFile} />
              ) : (
                <>
                  <div className="alert alert-warning text-center" role="alert">
                    Current document is not associated with an Slidecollab{" "}
                    {context.userTaskTrans}
                  </div>
                  <AppLink />
                </>
              )}
            </Tab>
            <Tab eventKey="tasks" title={"Active " + context.userTasksTrans}>
              <TasksList
                items={items}
                itemsRefresh={setItemsRefresh}
                getItems={getItems}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export { Dashboard };
