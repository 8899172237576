import React, { createContext, useState, useContext, ReactNode } from "react";

// Define the shape of your context
interface GlobalContextType {
  currentUser: string;
  setCurrentUser: (value: string) => void;
  currentToken: string;
  setCurrentToken: (value: string) => void;
  currentTask: string;
  setCurrentTask: (value: string) => void;
  currentTaskFileLock: boolean;
  setCurrentTaskFileLock: (value: boolean) => void;
  userCollabTrans: string;
  setUserCollabTrans: (value: string) => void;
  userCollabsTrans: string;
  setUserCollabsTrans: (value: string) => void;
  userTaskTrans: string;
  setUserTaskTrans: (value: string) => void;
  userTasksTrans: string;
  setUserTasksTrans: (value: string) => void;
  currentUserName: string;
  setCurrentUserName: (value: string) => void;
}

// Create the context
const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

// Create a custom hook for easier consumption of the context
export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error("useGlobalContext must be used within a GlobalProvider");
  }
  return context;
};

// Define the type for the props of GlobalProvider
interface GlobalProviderProps {
  children: ReactNode; // This is the key change
}

// Create the provider component
export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<string>("");
  const [currentToken, setCurrentToken] = useState<string>("");
  const [currentTask, setCurrentTask] = useState<string>("");
  const [currentTaskFileLock, setCurrentTaskFileLock] = useState<boolean>(
    false
  );
  const [userCollabTrans, setUserCollabTrans] = useState<string>("Collaboration");
  const [userCollabsTrans, setUserCollabsTrans] = useState<string>("Collaborations");
  const [userTaskTrans, setUserTaskTrans] = useState<string>("Task");
  const [userTasksTrans, setUserTasksTrans] = useState<string>("Tasks");
  const [currentUserName, setCurrentUserName] = useState<string>("");

  return (
    <GlobalContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        currentToken,
        setCurrentToken,
        currentTask,
        setCurrentTask,
        currentTaskFileLock,
        setCurrentTaskFileLock,
        userCollabTrans,
        setUserCollabTrans,
        userCollabsTrans,
        setUserCollabsTrans,
        userTasksTrans,
        setUserTasksTrans,
        userTaskTrans,
        setUserTaskTrans,
        currentUserName,
        setCurrentUserName,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
